<template>
  <section class="contact-container">
    <transition name="fade-in-bottom-up" appear>
      <div v-show="showFooter" class="content-wrapper">
        <a
          href="https://www.bcocongresos.com/"
          target="_blank"
          rel="noopener noreferrer"
          class="logo"
        >
          <img src="../../assets/contact-footer/bco-logo-blue.png" alt class="logo">
        </a>

        <div class="contact-us">
          <p class="title" v-html="site.contact_tagline"></p>
          <p class="address" v-html="site.contact_address"></p>
          <p class="email" v-html="site.contact_email"></p>
          <p class="phone" v-html="site.contact_phone"></p>
          <p>
            <a href="http://www.bcocongresos.com">www.bcocongresos.com</a>
          </p>
        </div>

        <div class="info" v-html="site.footer">
        </div>

        <div class="social-media">
          <p class="title">{{ $t('followUs') }}</p>
          <a href="https://www.facebook.com/BcoCongreso/" target="_blank" rel="noopener noreferrer">
            <img
              :src="!fbImageHover ? defaultImageFb : hoverImageFb"
              @mouseover="fbImageHover = true"
              @mouseout="fbImageHover = false"
              alt
            >
          </a>
          <a href="https://twitter.com/BcoCongresos" target="_blank" rel="noopener noreferrer">
            <img
              :src="!twitterImageHover ? defaultImageTwitter : hoverImageTwitter"
              @mouseover="twitterImageHover = true"
              @mouseout="twitterImageHover = false"
              alt
            >
          </a>
          <a
            href="https://www.linkedin.com/company/bcocongresos/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              :src="!linkedinImageHover ? defaultImageLinkedin : hoverImageLinkedin"
              @mouseover="linkedinImageHover = true"
              @mouseout="linkedinImageHover = false"
              alt
            >
          </a>
          <a
            href="https://www.instagram.com/bcocongresos/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              :src="!instagramImageHover ? defaultImageInstagram : hoverImageInstagram"
              @mouseover="instagramImageHover = true"
              @mouseout="instagramImageHover = false"
              alt
            >
          </a>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ContactFooter',
  props: {
    showFooter: Boolean
  },
  computed: {
    ...mapGetters(["site"])
  },
  data: function () {
    return {
      fbImageHover: false,
      instagramImageHover: false,
      linkedinImageHover: false,
      twitterImageHover: false,
      hoverImageFb: require('../../assets/contact-footer/bt_facebook_hover.svg'),
      defaultImageFb: require('../../assets/contact-footer/bt_facebook_default.svg'),
      hoverImageInstagram: require('../../assets/contact-footer/bt_instagram_hover.svg'),
      defaultImageInstagram: require('../../assets/contact-footer/bt_instagram.svg'),
      hoverImageTwitter: require('../../assets/contact-footer/bt_Twitter_hover.svg'),
      defaultImageTwitter: require('../../assets/contact-footer/bt_Twitter.svg'),
      hoverImageLinkedin: require('../../assets/contact-footer/bt_linkedin_hover.svg'),
      defaultImageLinkedin: require('../../assets/contact-footer/bt_linkedin.svg'),
    }
  }
}
</script>

<style lang="scss" scoped>
section.contact-container {
  color: #6e6e6e;
  text-align: left;
  font-size: 14px;

  a.logo {
    display: block;
    margin-bottom: 17px;

    img {
      width: 140px;
    }
  }

  ::v-deep(.title) {
    font-weight: 600;
    color: $primary-black;
    font-size: 15px;
    margin-bottom: 13px;
  }

  .contact-us {
    margin-bottom: 45px;

    p {
      display: flex;
      margin-bottom: 10px;
    }

    .address::before {
      content: url("../../assets/contact-footer/ic_footer_contact_location.svg");
      margin-right: 5px;
    }

    .email::before {
      content: url("../../assets/contact-footer/ic_footer_contact_email.svg");
      margin-right: 5px;
    }

    .phone::before {
      content: url("../../assets/contact-footer/ic_footer_contact_phone.svg");
      margin-right: 5px;
    }
  }

  ::v-deep(.info) {

    p.title {
      font-weight: initial;
    }

    p {
      margin-bottom: 13px;
    }

    p:not(:first-child)::before {
      content: var(--icon-circular-check);
      margin-right: 5px;
    }
  }

  .social-media {
    a {
      margin-right: 45px;
    }
  }
}

@media (min-width: 768px) {
  section.contact-container {
    height: 205px;
    max-width: 1550px;
    margin: 25px 20px 0;

    .content-wrapper {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      grid-template-rows: 33% 33% 33%;
      grid-template-areas:
      "logo contact-us info"
      ". contact-us info"
      "social-media contact-us info"
    }

    ::v-deep(p.title) {
      margin-bottom: 20px;
    }

    a.logo {
      grid-area: logo;
      margin-left: 20px;
    }

    .contact-us {
      grid-area: contact-us;
      margin-bottom: 0;
    }

    .info {
      grid-area: info;
    }

    .social-media {
      grid-area: social-media;
      display: flex;
      align-self: end;
      margin-left: 20px;

      p.title {
        margin-bottom: 0;
        margin-right: 20px;
      }

      a {
        margin-right: 30px;
      }
    }

  }
}

// Animations

@media (min-width: 768px) {
  .fade-in-bottom-up-enter-active {
    opacity: 0;
    animation: fade-in-bottom-up-in 1s forwards;
    animation-delay: 0.4s;
  }

  @keyframes fade-in-bottom-up-in {
    from {
      transform: translateY(300px);
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
</style>
