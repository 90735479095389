<template>
  <footer>
    <transition name="fade-in-bottom" appear>
      <div v-show="showFooter" class="content-wrapper">
        <div>
          <a class="policies" href="javascript:privacy.show();">{{ $t('cookiesPolicy') }}</a>
          <span>|</span>
          <router-link :to="`/terms`"><p class="policies">{{ $tc('cancellationPolicy', 1) }}</p></router-link>
        </div>
        <div>
          <p>©BCO Congresos {{ site.current_year }}</p>
        </div>
      </div>
    </transition>
  </footer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'LegalFooter',
  props: {
    showFooter: Boolean
  },
  computed: {
    ...mapGetters(["site"]),
  }
}
</script>

<style lang="scss" scoped>

footer {
  height: 60px;

  .content-wrapper {
    background: rgba(233, 233, 233, 0.3);
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }

  p, a {
    color: $secondary-black;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    text-align: right;
    line-height: 14px;
    display: inline-block;
    opacity: 1;
    text-decoration: none;

    &.policies:hover {
      text-decoration: underline
    }
  }

  span {
    margin: 0 17px;
    color: #A8A8A8;
  }
}

// Animations

@media (min-width: 768px) {
  .footer-container.fp-section {
    .fp-tableCell {
      padding-bottom:60px;
      position:relative;
      footer {
        width:100%;
        position:absolute;
        bottom:0;
        left:0;
      }
    }
  }

  .fade-in-bottom-enter-active {
    opacity: 0;
    animation: fade-in-bottom-in 0.5s forwards;
    animation-delay: 0.4s;
  }

  @keyframes fade-in-bottom-in {
    from {
      transform: translateY(300px);
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

</style>
