<template>
  <div class="links" :class="{ mobile: isMobile }">
    <router-link :to="{ path: '/list', query: { walking: true } }" custom v-slot="{ href }">
      <a :class="isSelected('walking') ? 'selected' : ''" :href="href" @click="onLinkClick($event, { path: '/list', query: { walking: true } })">
        {{ $t('nearVenue') }}
      </a>
    </router-link>

    <router-link :to="{ path: '/list', query: { ecoFriendly: true } }" custom v-slot="{ href }">
      <a :class="isSelected('ecoFriendly') ? 'selected' : ''" :href="href" @click="onLinkClick($event, { path: '/list', query: { ecoFriendly: true } })">
        {{ $t('ecoFriendly') }}
      </a>
    </router-link>

    <router-link :to="{ path: '/list', query: { city: true } }" custom v-slot="{ href }">
      <a :class="isSelected('city') ? 'selected' : ''" :href="href" @click="onLinkClick($event, { path: '/list', query: { city: true } })">
        {{ $t('cityCenter') }}
      </a>
    </router-link>


    <router-link :to="{ path: '/list'}" custom v-slot="{ href }">
      <a :class="allHotels() ? 'selected' : ''" :href="href" @click="onLinkClick($event, {path: '/list'})">
        {{ $t('seeAllHotels') }}
      </a>
    </router-link>

  </div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
  },
  methods: {
    isSelected(key = '') {
      return key in this.$route.query
    },
    allHotels() {
      return Object.keys(this.$route.query).length === 0
    },
    onLinkClick(event , to) {
      event.preventDefault();
      this.$store.commit("SET_PREV_URL", '/')
      this.$emit('clickOnShowAllHotels')
      this.$router.push(to)
    }
  }
};
</script>

<style lang="scss" scoped>
.links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding-left: 60px;
  height: 100%;

  a {
    flex: 0 0 auto;
    color: gray;
    text-decoration: none;
    display: inline-block;
    position: relative;
    font-weight: bold;
    margin: 0 10px;
    font-size: 16px;

    &:after {
      content: "";
      display: block;
      margin: auto;
      height: 2px;
      width: 0px;
      background: transparent;
      transition: width 0.5s ease, background-color 0.5s ease;
    }

    &:hover:after {
      width: 100%;
      background: gray;
    }
  }
}

.mobile a {
  line-height: 77px;
}

.selected {
  color: $primary-black !important;

  &:after {
    width: 100% !important;
    background: $primary-black !important;
  }
}
</style>
