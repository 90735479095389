<template>
  <nav class="container" v-bind:class="{ show: isNavbarOn }">
    <div class="logo">
      <router-link :to="`/`">
        <img :src="site.navbar_logo" alt="congress_logo"/>
      </router-link>
    </div>
    <links v-if="showLinks" class="links" @clickOnShowAllHotels='clickOnShowAllHotelsParent'></links>
  </nav>
</template>

<script>
import Links from './../components/Links'
import {mapState} from 'vuex'

export default {
  components: {
    Links
  },
  props: {
    showNavbar: {
      type: Boolean,
      default: false
    },
    showLinks: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      isNavbarOn: this.showNavbar,
    }
  },
  methods: {
    handleScroll() {
      this.isNavbarOn = window.scrollY > 700
    },
    clickOnShowAllHotelsParent() {
      this.$emit('clickOnShowAllHotelsParent')
    }
  },
  computed: {
    ...mapState(['site']),
  },
  watch: {
    showNavbar(newValue) {
      this.isNavbarOn = newValue
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
nav {
  height: 60px;
  transform: translateY(-100%);
  transition: 0.3s all ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: white;

  font-weight: bold;
  font-size: 14px;

  a {
    color: gray;
    text-decoration: none;
  }

  .logo {
    height: 40px;
    margin: 10px 0;

    img {
      height: 100%;
    }
  }
}

.show {
  transform: translateY(0%);
  box-shadow: 0px 1px 10px gray;
  transition: 0.3s all ease;
}

.container {
  padding: 0 60px;
  z-index: 1000;
}

@media only screen and (max-width: 768px) {
  .links {
    display: none;
  }

  .container {
    padding: 0 20px;
  }
}

</style>
